<template>
  <div>
    <v-card elevation="0">
      <v-card-title>{{ $t('spe.marutApp.employee.details.securityData.updatePin.title') }}</v-card-title>
      <v-card-text>
        <div v-if="hasError" class="v-text-field__details" style="padding-bottom: 16px">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">{{ resetError }}</div>
            </div>
          </div>
        </div>
        <div v-if="!isSubmitting && !resetSucceeded" style="display: flex; flex-wrap: wrap; width: 220px">
          <div>
            <v-text-field
              v-model="oldPassword"
              :error-messages="vuelidateErrors($v.oldPassword, { required, min: 4, max: 4 })"
              :placeholder="$t('spe.marutApp.employee.form.oldPassword')"
              @blur="$v.oldPassword.$touch()"
              @click:clear="clearOldPassword"
              background-color="white"
              class="pwd"
              clearable
              dense
              height="46"
              item-color="black"
              outlined
              required
              maxlength="4"
            >
            </v-text-field>
          </div>
          <div class="break"></div>
          <div>
            <v-text-field
              v-model="password"
              :disabled="$v.oldPassword.$anyDirty && $v.oldPassword.$invalid"
              :error-messages="vuelidateErrors($v.password, { required, min: 4, max: 4 })"
              :placeholder="$t('spe.marutApp.employee.form.newPassword')"
              @blur="$v.password.$touch()"
              @click:clear="clearPassword"
              background-color="white"
              class="pwd"
              clearable
              dense
              height="46"
              item-color="black"
              outlined
              required
              maxlength="4"
            >
            </v-text-field>
          </div>
          <div class="break"></div>
          <div>
            <v-text-field
              v-model="confirmPassword"
              :disabled="$v.password.$anyDirty && $v.password.$invalid"
              :error-messages="vuelidateErrors($v.confirmPassword, { required, min: 4, max: 4 })"
              :placeholder="$t('spe.marutApp.employee.form.confirmPassword')"
              @blur="$v.confirmPassword.$touch()"
              @click:clear="clearConfirmPassword"
              background-color="white"
              class="pwd"
              clearable
              dense
              height="46"
              item-color="black"
              outlined
              required
              maxlength="4"
            >
            </v-text-field>
          </div>
          <div class="break"></div>
          <div>
            <!-- v-btn disappears when dark prop and disabled prop are used together-->
            <!-- Use color="black" and class="white--text" SEE: <a href="https://github.com/vuetifyjs/vuetify/issues/4482"></a>-->
            <v-btn
              :disabled="$v.$invalid"
              block
              class="white--text"
              color="black"
              @click="handleSubmit"
              v-text="$t('spe.marutApp.employee.details.securityData.updatePin.button')"
            >
            </v-btn>
          </div>
        </div>
        <div v-if="resetSucceeded" style="display: flex; flex-wrap: wrap">
          <div class="py-4">
            <v-label> {{ $t('spe.marutApp.employee.details.securityData.updatePin.validationMessages.notificationSuccess') }}</v-label>
          </div>
          <div class="break"></div>
          <div>
            <v-btn block class="white--text" color="black" @click="goBack" v-text="$t('spe.marutApp.employee.back')"> </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts" src="./security-data.component.ts"></script>

<style scoped>
/*
 * Masking input characters without type=password
 */
@font-face {
  font-family: 'Password';
  font-style: normal;
  font-weight: 400;
  src: url(~@/../content/scss/spe/fonts/rafael-castro-couto/password.ttf) format('truetype');
}

.pwd >>> input:not(:placeholder-shown) {
  font-family: 'password';
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
