<template>
  <v-container id="webgradients_045_loon_crest" fill-height fluid style="flex-wrap: wrap">
    <v-layout column wrap>
      <v-flex style="flex: 1" xs12>
        <v-alert v-model="alert" background-color="white" block color="error" dense dismissible type="error">
          <span v-html="alertMessage"></span>
        </v-alert>
        <v-container md4 sm8 style="max-width: 432px" xs12>
          <v-card-text class="text-center">
            <div class="my-6">
              <v-icon color="white" size="96">mdi-account-group</v-icon>
            </div>
            <div class="mb-6 subtitle-1 text-h5 font-weight-light white-text">
              <span class="white--text">{{ $t('login.title') }}</span>
            </div>

            <v-form
              ref="form"
              :autocomplete="new Date().getTime().toString()"
              class="form"
              lazy-validation
              @submit="login"
              v-on:submit.prevent
            >
              <v-autocomplete
                ref="autocomplete"
                v-model="employee"
                @change="onSelectedEmployee"
                :autocomplete="new Date().getTime().toString()"
                :filter="customFilter"
                :items="employees"
                :label="$t('login.form[\'username.placeholder\']')"
                :loading="isLoading"
                :placeholder="$t('login.form[\'username.placeholder\']')"
                :search-input.sync="search"
                background-color="white"
                class="mb-2 autocomplete"
                clearable
                dark
                dense
                height="46"
                hide-details
                hide-no-data
                hide-selected
                item-color="black"
                item-text="name"
                required
                return-object
                solo-inverted
              >
              </v-autocomplete>

              <v-text-field
                :id="createNewPasswordUniqueId()"
                v-model="password"
                :autocomplete="createNewPasswordUniqueId()"
                :name="createNewPasswordUniqueId()"
                :placeholder="$t('login.form[\'password.placeholder\']')"
                background-color="white"
                class="mb-2 pwd"
                clearable
                dense
                height="46"
                item-color="black"
                outlined
                required
                maxlength="4"
                @keyup.enter="login"
              >
              </v-text-field>

              <v-btn :disabled="!isPasswordSet" block class="primary-btn" dark height="46" @click="login" v-text="$t('login.form.button')">
                Sign in
              </v-btn>

              <div>
                <div v-if="personalPin" class="ma-3">
                  <v-btn
                    :to="{ name: 'PinForgot' }"
                    color="white"
                    plain
                    style="text-transform: none"
                    v-text="$t('spe.marutApp.employee.forgotPin.button')"
                  >
                  </v-btn>
                </div>

                <div v-if="!personalPin" class="ma-3">
                  <v-btn
                    :to="{ name: 'PinCreate', params: { ...employee } }"
                    color="white"
                    plain
                    style="text-transform: none"
                    v-text="$t('spe.marutApp.employee.createPin.button')"
                  >
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-container>
      </v-flex>
      <v-flex align-self-end style="flex: 0; width: 100%" xs12></v-flex>
    </v-layout>
  </v-container>
</template>
<script lang="ts" src="./employee-authentication.component.ts"></script>
<!-- eslint-disable -->
<style scoped>
/* Override vuetify label color */
.autocomplete >>> label {
  color: #9e9e9e !important;
}

/* Override vuetify input color */
.autocomplete >>> input {
  color: black !important;
}

/**
 * https://webgradients.com/
 */
#webgradients_045_loon_crest {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 120%) #989898;
  background-blend-mode: multiply, multiply;
}

/* Other choices
 #webgradients_071_above_clouds {
   background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
   background-blend-mode: normal, lighten, soft-light;
 }
 #webgradients_081_mountain_rock {
   background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
 }
 #webgradients_099_strong_stick {
   background-image: linear-gradient(to right, #a8caba 0%, #5d4157 100%);
 }
 #webgradients_100_vicious_stance {
   background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
 }
 #webgradients_105_raccoon_back {
   background: linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%);
   background-blend-mode: screen;
 }
 #webgradients_132_solid_stone {
   background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
 }
 #webgradients_140_mole_hall {
    background-image: linear-gradient(-20deg, #616161 0%, #9bc5c3 100%);
 }
 #webgradients_145_rich_metal {
   background-image: linear-gradient(to right, #d7d2cc 0%, #304352 100%);
 }
*/

/*
 * Masking input characters without type=password
 */
@font-face {
  font-family: 'Password';
  font-style: normal;
  font-weight: 400;
  src: url(~@/../content/scss/spe/fonts/rafael-castro-couto/password.ttf) format('truetype');
}

.pwd >>> input:not(:placeholder-shown) {
  font-family: 'password';
}
</style>
