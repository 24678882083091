<template>
  <v-container fluid>
    <v-subheader>
      <h2>{{ $t('marutApp.employeeAbsence.home.title') }}</h2>
    </v-subheader>
    <v-row>
      <v-col cols="12">
        <!-- DISABLE VCARD
        <v-card>
          <v-card-title>
        -->
        <v-row v-if="!alertMessageProperties.visible">
          <v-col cols="12" sm="12" md="4">
            <v-list-item>
              <div v-if="!employee.image.image">
                <v-list-item-avatar>
                  <v-icon style="font-size: 40px">mdi-account-circle-outline</v-icon>
                </v-list-item-avatar>
              </div>
              <div v-else-if="employee.image.image">
                <v-list-item-avatar>
                  <v-img :src="employee.image.image"></v-img>
                </v-list-item-avatar>
              </div>
              <v-list-item-content>
                <v-list-item-title v-text="employee.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <!-- ABSENCE TYPE FIELD -->
            <v-select
              :value="fieldAbsenceType"
              @input="setAbsenceType"
              :items="employeeAbsenceTypes"
              item-text="description"
              return-object
              dense
              outlined
              background-color="white"
              :placeholder="$t('marutApp.employeeAbsence.absenceCode')"
              :hint="daysInAdvanceHint"
              persistent-hint
              @blur="
                $v.employeeAbsence.absenceCode.$touch();
                $v.employeeAbsence.absenceDescription.$touch();
              "
              :error-messages="vuelidateErrors($v.employeeAbsence.absenceCode, {})"
            ></v-select>
          </v-col>

          <!-- DATE FIELDS -->
          <v-dialog ref="dateDialog" v-model="modalDates" :return-value.sync="fieldDates" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="datesSorted[1]"
                  class="second-input"
                  :placeholder="$t('marutApp.employeeAbsence.expectedEndDate')"
                  background-color="white"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :error-messages="vuelidateErrors($v.employeeAbsence.expectedEndDate, {})"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="datesSorted[0]"
                  :placeholder="$t('marutApp.employeeAbsence.expectedStartDate')"
                  background-color="white"
                  :hint="expectedStartDateHint"
                  persistent-hint
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="v-messages__message"
                  :error-messages="vuelidateErrors($v.employeeAbsence.expectedStartDate, {})"
                ></v-text-field>
              </v-col>
            </template>
            <v-date-picker
              v-model="fieldDates"
              scrollable
              range
              :min="minDate"
              :allowed-dates="allowedDates"
              @blur="
                $v.employeeAbsence.expectedStartDate.$touch();
                $v.employeeAbsence.expectedEndDate.$touch();
              "
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDates = false">
                {{ $t('entity.action.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.dateDialog.save(fieldDates)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-col cols="12" sm="6" md="4">
            <!-- START HOUR FIELD -->
            <v-dialog
              ref="startHourDialog"
              v-model="modalHourStart"
              :return-value.sync="employeeAbsence.expectedStartTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="employeeAbsence.expectedStartTime"
                  :placeholder="$t('marutApp.employeeAbsence.expectedStartTime')"
                  background-color="white"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :error-messages="vuelidateErrors($v.employeeAbsence.expectedStartTime, {})"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="employeeAbsence.expectedStartTime"
                format="24hr"
                :allowed-minutes="allowedMinutes"
                min="6:00"
                max="23:00"
                @blur="$v.employeeAbsence.expectedStartTime.$touch()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalHourStart = false">
                  {{ $t('entity.action.cancel') }}
                </v-btn>
                <v-btn text color="primary" @click="$refs.startHourDialog.save(employeeAbsence.expectedStartTime)"> OK </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="endHourDialog"
              v-model="modalHourEnd"
              :return-value.sync="employeeAbsence.expectedEndTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="employeeAbsence.expectedEndTime"
                  :placeholder="$t('marutApp.employeeAbsence.expectedEndTime')"
                  background-color="white"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="second-input"
                  :error-messages="vuelidateErrors($v.employeeAbsence.expectedEndTime, {})"
                ></v-text-field>
              </template>
              <v-time-picker
                ref="endHourRef"
                v-model="employeeAbsence.expectedEndTime"
                format="24hr"
                :allowed-minutes="allowedMinutes"
                :allowed-hours="allowedHours"
                min="6:00"
                max="23:00"
                @click:hour="checkIfSameHour"
                @blur="$v.employeeAbsence.expectedEndTime.$touch()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalHourEnd = false">
                  {{ $t('entity.action.cancel') }}
                </v-btn>
                <v-btn text color="primary" @click="$refs.endHourDialog.save(employeeAbsence.expectedEndTime)"> OK </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              v-model="employeeAbsence.description"
              name="input-7-4"
              :placeholder="$t('marutApp.employeeAbsence.description')"
              background-color="white"
              dense
              outlined
              :maxlength="descriptionMaxLength"
              :counter="descriptionMaxLength"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-else>
          <alert-message :properties="alertMessageProperties" @click="onAlertMessageClick"></alert-message>
        </v-row>
        <!-- DISABLE VCARD
          </v-card-title>
        </v-card>
        -->
      </v-col>
    </v-row>

    <portal to="bottomNavigation">
      <v-bottom-navigation app dark>
        <v-btn icon @click="home()">
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn :disabled="isSaving" icon @click="cancel">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>

        <v-btn icon :disabled="$v.$invalid || isSaving" @click="save">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </portal>

    <v-overlay v-if="isSaving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script lang="ts" src="./employee-absence-update.component.ts"></script>
<style>
.v-messages__message {
  color: red;
}
</style>
