
@import '@/../content/scss/spe/global.scss';
#error {
  #error-content {
    .primary-text {
      font-size: $primary-text-size;
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
    }
  }
}

/* MEDIA QUERIES*/
@media only screen and (max-width: 700px) {
  #error {
    #error-content {
      .primary-text {
        font-size: $primary-text-size-m;
      }
    }
  }
}
