<template>
  <div class="vue-pincode-input-wrapper">
    <label for="pin">
      <input
        id="pin"
        v-for="(cell, index) in cells"
        :key="cell.key"
        :ref="`${baseRefName}${index}`"
        v-model.trim="cell.value"
        v-bind="$attrs"
        class="vue-pincode-input"
        :type="cellsInputTypes[index]"
        @focus="focusedCellIdx = index"
        @keydown.delete="onCellErase(index, $event)"
        @keydown="onKeyDown"
      />
    </label>
  </div>
</template>

<!-- eslint-disable -->
<script lang="ts" src="./vue-pin-code-input.component.ts"></script>

<style>
.vue-pincode-input-wrapper {
  display: inline-flex;
}

.vue-pincode-input {
  outline: none;
  margin: 3px;
  padding: 5px;
  max-width: 40px;
  text-align: center;
  font-size: 1.1rem;
  border: none;
  border-radius: 3px;
  /*box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);*/
  background-color: white;
}

.vue-pincode-input:focus {
  /*box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);*/
}
</style>
