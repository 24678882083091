@import 'fonts';
@import 'colors';

$title-font: 'Sansation', sans-serif !important;
$title-font-size: 77px !important;

$primary-font: 'Nunito', sans-serif !important;
$primary-text-size: 28px !important;
$primary-text-size-m: 20px !important;

$secondary-text-size: 20px !important;
$secondary-text-size-m: 16px !important;

.secondary-background {
  background-color: $lighter-background-color;
}

.primary-btn {
  background-color: $dark-btn-background-color;
  color: $light-text-color;
  font-size: 16px !important;
}
