$sansation_font_path: '~@/../content/scss/spe/fonts/Sansation/';

@font-face {
  font-family: 'Sansation';
  src: url($sansation_font_path + 'Sansation_Regular.ttf');
  src: url($sansation_font_path + 'Sansation_Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  src: url($sansation_font_path + 'Sansation_Bold.ttf');
  src: url($sansation_font_path + 'Sansation_Bold.ttf') format('truetype');
  font-weight: 700;
}

$nunito_font_path: '~@/../content/scss/spe/fonts/Nunito/static/';

@font-face {
  font-family: 'Nunito';
  src: url($nunito_font_path + 'Nunito-Regular.ttf');
  src: url($nunito_font_path + 'Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: url($nunito_font_path + 'Nunito-Bold.ttf');
  src: url($nunito_font_path + 'Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
}
