<!-- eslint-disable -->
<template>
  <v-container id="webgradients_045_loon_crest" fill-height fluid style="flex-wrap: wrap">
    <v-progress-linear v-if="isSubmitting" indeterminate style="position: absolute; left: 0px; top: 0px"></v-progress-linear>
    <v-layout column wrap>
      <v-flex style="flex: 1" xs12>
        <!--        <v-alert v-model="alert.enable" :type="alert.type" background-color="white" block dense dismissible>-->
        <!--          <span v-html="alert.message"></span>-->
        <!--        </v-alert>-->
        <div v-if="!alertMessageProperties.visible">
          <v-container md4 sm8 style="max-width: 432px" xs12>
            <v-card-text class="text-center">
              <div class="my-6">
                <v-icon color="white" size="96">mdi-account-group</v-icon>
              </div>

              <div class="mb-6 subtitle-1 text-h5 font-weight-light white-text">
                <span v-if="this.$route.name === 'PinCreate'" class="white--text"> {{ $t('spe.marutApp.employee.createPin.title') }}</span>
                <span v-else class="white--text"> {{ $t('spe.marutApp.employee.forgotPin.title') }} </span>
              </div>

              <div v-if="this.$route.name === 'PinCreate' || this.$route.name === 'PinReset'">
                <v-form>
                  <v-text-field
                    v-if="hasPassword"
                    v-model="password"
                    :placeholder="$t('spe.marutApp.employee.form.newPassword')"
                    :error-messages="vuelidateErrors($v.password, { required, min: 4, max: 4 })"
                    @blur="$v.password.$touch()"
                    background-color="white"
                    class="pwd"
                    clearable
                    dense
                    height="46"
                    item-color="black"
                    outlined
                    required
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="confirmPassword"
                    :disabled="$v.password.$anyDirty && $v.password.$invalid"
                    :placeholder="$t('spe.marutApp.employee.form.confirmPassword')"
                    :error-messages="vuelidateErrors($v.confirmPassword, { min: 4, max: 4 })"
                    @blur="$v.confirmPassword.$touch()"
                    background-color="white"
                    class="pwd"
                    clearable
                    dense
                    height="46"
                    item-color="black"
                    outlined
                    required
                  ></v-text-field>

                  <div v-if="isPasswordSet && password !== confirmPassword" class="ma-auto">
                    <small class="form-text" style="color: red">
                      {{ $t('spe.marutApp.employee.details.securityData.updatePin.validationMessages.passwordMatch') }}
                    </small>
                  </div>

                  <v-btn
                    :disabled="!passwordMatch"
                    :loading="isSubmitting"
                    block
                    class="primary-btn"
                    color="dark"
                    dark
                    height="46"
                    style="margin-bottom: 15px"
                    @click="handleSubmit"
                    v-text="$t('spe.marutApp.employee.submitButton')"
                  >
                    Submit
                  </v-btn>

                  <v-btn
                    :to="{ name: 'EmployeeAuthentication' }"
                    block
                    class="primary-btn"
                    dark
                    height="46"
                    v-text="$t('spe.marutApp.employee.back')"
                  >
                    Main Page
                  </v-btn>
                </v-form>
              </div>
            </v-card-text>
          </v-container>
        </div>
        <div v-else>
          <alert-message :properties="alertMessageProperties" @click="onAlertMessageClick"></alert-message>
        </div>
      </v-flex>
      <v-flex align-self-end style="flex: 0; width: 100%" xs12></v-flex>
    </v-layout>
  </v-container>
</template>
<script lang="ts" src="./pin-create-or-update.component.ts"></script>
<!-- eslint-disable -->
<style scoped>
/* Override vuetify label color */
.autocomplete >>> label {
  color: #9e9e9e !important;
}

/* Override vuetify input color */
.autocomplete >>> input {
  color: black !important;
}

/**
 * https://webgradients.com/
 */
#webgradients_045_loon_crest {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 120%) #989898;
  background-blend-mode: multiply, multiply;
}

/*
 * Masking input characters without type=password
 */
@font-face {
  font-family: 'Password';
  font-style: normal;
  font-weight: 400;
  src: url(~@/../content/scss/spe/fonts/rafael-castro-couto/password.ttf) format('truetype');
}

.pwd >>> input:not(:placeholder-shown) {
  font-family: 'password';
}
</style>
